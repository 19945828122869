export default () => ({
  tab: 0,
  init() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.get('tab')) {
      this.tab = urlParams.get('tab');
    } else {
      this.tab = 0;
    }
  },
});
