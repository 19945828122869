let trigger = true;
const headerTop = 51;

function repeatOften() {
  const header = document.getElementById('header');
  const currentScrollPos = window.scrollY;
  if (!header) {
    return;
  }
  if (currentScrollPos > headerTop && !trigger) {
    header.classList.add('header--top');

    trigger = true;
  } else if (currentScrollPos < headerTop && trigger) {
    header.classList.remove('header--top');
    trigger = false;
  }

  requestAnimationFrame(repeatOften);
}

requestAnimationFrame(repeatOften);
