export default () => ({
  init() {
    const textarea = this.$el;
    if (textarea) {
      textarea.addEventListener('input', () => {
        textarea.style.height = '26px';
        textarea.style.height = textarea.scrollHeight + 'px';
      });
    }
  },
});
