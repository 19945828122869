import Alpine from 'alpinejs';
import persist from '@alpinejs/persist';
import Tabs from './components/tabs';
import Dropdown from './components/dropdown';
import SignUp from './components/sign-up';
import TextareaAutoheight from './components/textarea-autoheight';

window.Alpine = Alpine;

Alpine.plugin(persist);
Alpine.data('tabs', Tabs);
Alpine.data('dropdown', Dropdown);
Alpine.data('signUp', SignUp);
Alpine.data('textareaAutoheight', TextareaAutoheight);

Alpine.start();
